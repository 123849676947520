'use client';
import {
  Flex,
  Grid,
  GridItem,
  Text,
  Box,
  Button,
  VStack,
  Stack,
  Input,
  Center,
  Textarea,
  FormHelperText,
  FormControl,
  
} from '@chakra-ui/react';
import apiHelpers from '@/utils/apiHelpers';
import React, { useState } from 'react';
import Swal from 'sweetalert2';

const ContactUs = () => {
  const initialData={
    name:'',
    email:'',
    phone:'',
    message:'',
    extra:'home'
}
const [contact, setContact]= useState(initialData);
const [loadingButton, setLoadingButton] = useState(false);
const [errors, setErrors] = useState({});

const handleInputChange = event => {
    const target = event.target;
    if(target.value==[]){
      target.value=null
    }
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

   
    setContact(prevState => {
  
      return { ...prevState, [name]: value };

    });

  };




  const validateEmail = email => {
    // Utiliza una expresión regular para validar el formato del correo electrónico
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = phone => {
    // Utiliza una expresión regular para validar el formato del número de teléfono
    const regex = /^[0-9]{10}$/;
    return regex.test(phone);
  };





  const validateForm=()=>{


    let newErrors={};
    if (!validateEmail(contact.email)) {
     newErrors = { ...newErrors, email: 'Ingrese un correo electrónico válido.' };
   }
  
   // Validar el campo de teléfono
   if (!validatePhone(contact.phone)) {
     newErrors = { ...newErrors, phone: 'Ingrese un número de teléfono válido.' };
   }
   if (Object.keys(newErrors).length > 0) {
    setErrors(newErrors);
  }
   else {
    // Envía el formulario si no hay errores
    contactMail();
  }
  }
  const handleFocus = event => {
    const name = event.target.name;
    // Limpiar el error cuando el campo vuelve a estar enfocado
    setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
  };
  





  const contactMail = async event => {
    //event.preventDefault();
    setLoadingButton(true);

    try {
      await apiHelpers.contactMail(
       contact
      );
      Swal.fire({
        title: 'Gracias!',
        text: 'Tu mail fue enviado con éxito.',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false, 
      
      }).then(() => {
        setContact(initialData)
        
      });
    } catch (error) {
      const statusCode = error.response?.status;
      let errorMessage;
      Swal.fire({
        title: 'Error!',
        text: 'Hubo un error, intenta nuevamente',
        icon: 'error',
        timer: 3000, 
        showConfirmButton: false, 
        customClass: {
          popup: 'sweetalert-index', 
        },
      
      }).then(() => {
        

      
      });
      if (statusCode === 401) {
        errorMessage = 'Error de envio';
      } else {
        errorMessage = error.message;
      }
      
      
    }
    setLoadingButton(false);
  };




  return (
 
    <Box
    w={{ base: '100%', sm: '100%', md: '80%', lg: '70%' }}
    display={'flex'}
  flexDirection={{base:'column',sm:'row',md:'row',lg:'row'}}
    alignSelf='center'
    height={{base:'400px',sm:'350px', md:'300px'}}
    m={'0 auto'}
   
    
  >
        <Center
        // bgColor={'#e1a360'}
         w={{ base: '100%', sm: '50%', md: '55%', lg: '50%' }}
         h={'auto'}
       
         //borderRightRadius={{ sm: 'none', md: '200px' }}
         position="relative"
         zIndex={2}
         display={'flex'}>
           
          <Text
          as='h2'
            fontSize={{base:'1.2rem',sm:'1.5rem', md:'1.7rem', lg:'1.7rem', xl:'1.8rem','2xl':'2rem'}}
            m={{base:'10px',sm:'10px', md:'30px', lg:'30px', xl:'3px'}}
            color="#E77800!important"
            fontWeight="600"
  
            fontFamily="Heebo, sans-serif"
            textAlign={{base:'center',sm:'center', md:'left', lg:'left', xl:'left'}}
            lineHeight="1.4"
            maxWidth={'100%'}
            letterSpacing="2px">
             TENEMOS LO QUE<br/>
              ESTÁS BUSCANDO.<br/>
             CONSULTANOS!
          
          </Text>
        </Center>


        <Box  w={{ base:'100%', sm: '50%', md: '45%',lg:'50%'}}
    p='5'
    justifyContent={{ sm: 'flex-end', md: "center" }}
 
    zIndex={1}>
          <FormControl  pt={{sm:'20px'}}spacing={2} color={'black'} >
          <Stack spacing={2} >

<Input  onFocus={handleFocus} placeholder="Nombre" id='name' name={'name'}
    value={contact.name}
    disabled={loadingButton}
    onChange={handleInputChange} size="sm" />
    
<Input  onFocus={handleFocus} placeholder="Email" id='email' name={'email'}
    value={contact.email}
    disabled={loadingButton}
    onChange={handleInputChange} size="sm" />
     {errors.email && <small class="text-sm text-red-500 mt-[3px] font-bold ">{errors.email}</small>}
<Input  onFocus={handleFocus} name={'phone'} id='phone' onChange={handleInputChange}
    disabled={loadingButton}
    value={contact.phone} placeholder="Teléfono" size="sm" />
    {errors.phone && <small class="text-sm text-red-500 mt-[3px] font-bold">{errors.phone}</small>}
<Textarea onFocus={handleFocus} name={'message'}
    disabled={loadingButton}
    value={contact.message}
    onChange={handleInputChange} placeholder="Mensaje" />
    
<Button  isLoading={loadingButton}
  justifyContent="center" 
  color="white"
  _hover={{
    bgColor: '#f8b46b',
    color: 'grey',
  }}
  backgroundColor="#E77800 !important"
  loadingText="Enviando" 
  onClick={validateForm}>Enviar</Button>
</Stack>
</FormControl>
        </Box>
</Box>
)
};

export default ContactUs;
