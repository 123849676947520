'use client';
import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  MdOutlineMapsHomeWork,
MdBusiness,
MdOutlineFactory,
MdOutlineStorefront,
MdOutlineStore,

} from 'react-icons/md';
import apiHelpers from '@/utils/apiHelpers';

const IwinData = props => {





  return (
    <Box
      display={'flex'}
      width={'90%'}
      alignItems="center"
      margin={'0 auto'}
      justifyContent={{ base: 'center', sm: 'space-around', md: 'space-around' }}
      flexWrap={{ base: 'wrap', sm: 'nowrap' }}
    
      flexDirection={{
        base: 'column',
        sm: 'row',
        md: 'row',
        lg: 'row',
        xl: 'row',
      }}>
      <Box
        display={'flex'}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        margin={{base:'35px', sm:'15px', md: '15px', lg: '15px' }}>
        <MdOutlineMapsHomeWork color="white" size={50} />
        <Text
     
         fontSize={{base:'20px', sm:'25px', md: '25px', lg: '35px' }}
          mt={'20px'}
          color="white"
          fontWeight="400"
          textAlign="center" 
          fontFamily="Heebo, sans-serif"
          lineHeight="1.2">
          {props.porpertiesOnsale}
        </Text>
        <Text
         as='h2'
          fontSize={{base:'18px', sm:'20px', md: '22px', lg: '25px' }}
          color="white"
          mt={'8px'}
          fontWeight="300"
          fontFamily="Heebo, sans-serif"
          lineHeight="1.2"
          textAlign="center" >
          Propiedades en venta
        </Text>
      </Box>
      <Box
        display={'flex'}
        flexDirection="column"
        alignItems="center"
        margin='15px'
        justify={'center'}>
        <MdOutlineStorefront color="white" size={50} />
        <Text
     
          fontSize={{base:'20px', sm:'25px', md: '25px', lg: '35px' }}
          mt={'20px'}
          color="white"
          fontWeight="400"
          textAlign="center" 
          fontFamily="Heebo, sans-serif"
          lineHeight="1.2">
          {props.avilableLocalsCount}
        </Text>
        <Text
           as={'h2'}
         fontSize={{base:'18px', sm:'20px', md: '22px', lg: '25px' }}
          color="white"
          mt={'8px'}
          fontWeight="300"
          fontFamily="Heebo, sans-serif"
          lineHeight="1.2"
          textAlign="center" >
          Locales y oficinas
        </Text>
      </Box>
      <Box
        display={'flex'}
        flexDirection="column"
        alignItems="center"
        margin='15px'
        justify={'center'}>
        <MdBusiness  color="white" size={50} />
        <Text
         fontSize={{base:'20px', sm:'25px', md: '25px', lg: '35px' }}
          mt={'20px'}
          color="white"
          fontWeight="400"
          textAlign="center" 
          fontFamily="Heebo, sans-serif"
          lineHeight="1.2">
          {props.developmentsAvilable}
        </Text>
        <Text
           as={'h2'}
         fontSize={{base:'18px', sm:'20px', md: '22px', lg: '25px' }}
          color="white"
          mt={'8px'}
          fontWeight="300"
          fontFamily="Heebo, sans-serif"
          lineHeight="1.2"
          textAlign="center" >
         Emprendimientos
        </Text>
      </Box>
      {/*<Flex flexDirection="column" alignItems="center" justify={'center'}>
      <MdAttachMoney color="white" size={55} />
      <Text
        fontSize="35px"
        mt={'20px'}
        color="white"
        fontWeight="400"
        textShadow="2px 2px 6px rgba(239, 233, 233, 0.6)"
        fontFamily="Heebo, sans-serif"  lineHeight="1.2"
      >
        {props.investment}
      </Text>
      <Text
        fontSize="20px"
 
        color="white"
        fontWeight="300"

        fontFamily="Heebo, sans-serif"  lineHeight="1.2"
      >
        Emprendimientos
      </Text>
    </Flex>*/}
    </Box>
  );
};

export default IwinData;
