'use client'
import  {useContext, useState, useEffect } from 'react';
import { Grid, GridItem, Image, Box, Text ,Center,Spinner} from '@chakra-ui/react';
import { useRouter } from 'next/navigation';
import FilterContext from '@/components/FilterContext';
import formatter from "@/utils/formatter";
import Link from 'next/link';

const MostChosen = ({ images }) => {
  const [options, setOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [state, setState]=useState({})
  const { push } = useRouter();
  const [loading, setLoading] = useState(false);
  const [filtersContext, setFiltersContext] = useContext(FilterContext);

 /* useEffect(() => {
    if (options.length) {
      setLoading(true);
      const params = [];
      for (const key in state) {
        if (state.hasOwnProperty(key)) {
          const value = state[key];
      
       
          if (Array.isArray(value)) {
    
            value.forEach(item => {
              params.push(`${key}[]=${encodeURIComponent(item)}`);
            });
          } else {
      
            params.push(`${key}=${encodeURIComponent(value)}`);
          }
        }
      }
      
      // Unir los parámetros en una cadena
      const queryString = params.join('&');

    push(`/propiedades?${queryString}`);


    }
  }, [options]);*/

  const chosenOptions = (input) => {


    //setSelectedLocation(formatter.capitalizeFirstLetter(input));
    setOptions(input)

    setState({
      fullLocation:input,
      starredOnweb: "1",
      postingType:  ['Propiedad','Emprendimiento'] ,
      realEstateType: ['Departamento', 'PH', 'Casa'],
      origin: ['Tokko'],
      selectedLocation: formatter.capitalizeFirstLetter(input),
    })    
    
 
  };

  const getFiltersObject = () => {
    return {
     
        fullLocation: options,
        starredOnweb: "1",
        postingType:  ['Propiedad','Emprendimiento'] ,
        realEstateType: ['Departamento', 'PH', 'Casa'],
        origin: ['Tokko'],
        selectedLocation: selectedLocation,
      }
  };

  const sections = ['nuñez', 'palermo', 'belgrano', 'las cañitas', 'recoleta'];

  return (
    <>
      <Box  margin={'25px auto'}>
        <Text
        as='h2'
          fontSize={{ sm: '20px', md: '25px', lg: '30px' }}
          m={{ base: '10px', sm: '10px', md: '15px', lg: '20px', xl: '20px' }}
          color="gray.600"
          fontWeight="400"
          fontFamily="Heebo, sans-serif"
          textAlign="center"
          lineHeight="1"
          letterSpacing="2px"
        >
          Los barrios más elegidos
        </Text>
        <Text
        
          fontSize={{ sm: '16px', md: '20px', lg: '25px' }}
          color="gray.600"
          fontWeight="400"
          fontFamily="Heebo, sans-serif"
          textAlign="center"
          lineHeight="1"
        >
          Encontrá propiedades en los mejores barrios de la ciudad
        </Text>
      </Box>

      <Grid
        h={['600px', '450px', '450px']}
        marginTop={'15px'}
        templateAreas={{
          base: `"nuñez" "palermo" "belgrano" "canitas" "recoleta"`,
          sm: `"nuñez nuñez" "palermo belgrano" "canitas recoleta"`,
          md: `"nuñez palermo belgrano" "nuñez canitas recoleta"`,
          lg: `"nuñez palermo belgrano" "nuñez canitas recoleta"`,
        }}
        gridTemplateRows={{
          sm: 'repeat(3)',
          md: '50% 50%',
          lg: '50% 50%',
        }}
        gridTemplateColumns={{ base: '100%', sm: '50% 50%', md: '40% 30% 30%', lg: '40% 30% 30%' }}
        gap="2"
        color="blackAlpha.700"
        fontWeight="bold"
      >
        {sections.map(section => {

const queryString = new URLSearchParams();
const state = {
  fullLocation: section,
  starredOnweb: "1",
  postingType: ['Propiedad', 'Emprendimiento'],
  realEstateType: ['Departamento', 'PH', 'Casa'],
  origin: ['Tokko'],
  selectedLocation: formatter.capitalizeFirstLetter(section),
};

Object.entries(state).forEach(([key, value]) => {
  if (Array.isArray(value)) {
    value.forEach(item => queryString.append(`${key}[]`, item));
  } else {
    queryString.append(key, value);
  }
});
return(
          <GridItem
            key={section}
            pl="2"
            backgroundColor={'black'}
            area={section}
            position="relative"
            overflow="hidden"
            borderRadius={'3px'}
            onClick={() => setLoading(true)}
            cursor={'pointer'}
          >
            <Link key={section} href={`/propiedades?${queryString.toString()}`} passHref>
            {images.map(image => {
              if (image.section === section) {
                return (
                  <Image
                    key={image.id}
                    src={image.url}
                    alt={section}
                    objectFit="cover"
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    transition={'transform 0.3s'}
                    opacity={'0.7'}
                    _hover={{ transform: 'scale(1.1)', opacity: '1' }}
                  />
                );
              }
            })}
            <Text
            as='h3'
              position="relative"
              top="10px"
              left="5px"
              zIndex="1"
              color="white"
              fontWeight="400"
              fontSize={'16px'}
              fontFamily="Heebo, sans-serif"
            >
              {section.charAt(0).toUpperCase() + section.slice(1)}
            </Text>
            </Link>
          </GridItem>)
})}
      </Grid>
      {loading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(255, 255, 255, 0.8)"
          zIndex="999"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
         <Center textAlign="center">
                      <Spinner m={3} size="xl" color='orange'/>
                     
                      <span className='text-orange-500 font-semibold font-heebo'>Cargando...</span>
                    
                                     
                    </Center>
        </Box>
      )}
    </>
  );
};

export default MostChosen;
